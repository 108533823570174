import React from 'react';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import { Helmet } from 'react-helmet';

import { createRoot } from 'react-dom/client';
const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Helmet>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_ID}`}></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.REACT_APP_GA_ID}');
        `}
      </script>
    </Helmet>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);